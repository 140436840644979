<template>
  <div>
    <section v-if="plans" class="section">
      <el-card
        shadow="hover"
        class="has-margin-vertical"
        v-for="plan in activePlans"
        :key="plan.id"
      >
        <div slot="header" class="is-clearfix">
          <el-button type="text" class="is-pulled-right">Archive Plan</el-button>
        </div>
        <div>
          <h3 class="is-size-5">{{ plan.name }}</h3>
          <p class="is-overline">Amount</p>
          <p class="is-size-6">{{ plan.amountFormatted }}</p>
          <p class="is-overline">Allowance</p>
          <p class="is-size-6">{{ plan.allowanceFormatted }}</p>
          <p class="is-overline">Status</p>
          <div>
            <el-tag v-if="plan.active" type="primary">Active</el-tag>
            <el-tag v-else type="info">Inactive</el-tag>
          </div>
          <p class="is-overline">Interval</p>
          <p>{{ plan.intervalCount }} {{ plan.interval }}</p>
          <p class="is-overline">Trial Period</p>
          <p v-if="plan.hasTrial">{{ plan.trialPeriodDays }} days</p>
          <p v-else>None</p>
        </div>
      </el-card>
    </section>
    <section>
      <h2 class="is-size-4-5">Add Plan</h2>
      <el-form label-position="top" size="small" class="has-margin-top">
        <el-form-item label="Name">
          <el-input type="text" v-model="newPlan.name"></el-input>
        </el-form-item>
        <el-form-item label="Description">
          <el-input type="textarea" :rows="4" v-model="newPlan.description"></el-input>
        </el-form-item>
        <el-form-item label="Cost per month">
          <el-input-number v-model="newPlan.amount" :precision="2" :step="1" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="Number of orders per month">
          <el-radio-group v-model="newPlan.allowanceType">
            <el-radio-button label="unlimited">Unlimited plan</el-radio-button>
            <el-radio-button label="limited">Limited allowance</el-radio-button>
          </el-radio-group>
          <br />
          <el-input-number
            v-if="newPlan.allowanceType === 'limited'"
            v-model="newPlan.allowance"
            :precision="0"
            class="has-margin-top"
            :step="1"
            :min="0"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <el-button @click="addPlan">+ Add Plan</el-button>
    </section>
  </div>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify'
import { getStripePlan, createStripePlan, createStripeProduct } from '@/api/stripe.service'
import Plan from '@/models/Plan'

const getServicePlans = `query GetService($id: ID!) {
  getService(id: $id) {
    id
    stripeId
    owner
    plans {
      items {
        id
        stripeId
        name
        description
        allowance
        owner
      }
      nextToken
    }
  }
}
`

const newPlanBase = {
  name: '',
  description: '',
  amount: 10,
  allowance: 10,
  allowanceType: 'limited', // or 'unlimited
}

export default {
  name: 'ServicePlansEdit',
  props: { id: String },
  data() {
    return {
      plans: null,
      newPlan: Object.assign({}, newPlanBase),
      service: null,
    }
  },
  async created() {
    const res = await API.graphql(graphqlOperation(getServicePlans, { id: this.id }))
    const service = res.data.getService
    this.service = service
    const stripePlansRes = await Promise.all(service.plans.items.map((p) => getStripePlan(p.id)))
    const stripePlans = stripePlansRes.map((p) => p.data)
    const plans = service.plans.items
      // .map(plan => Object.assign({ }, plan, stripePlans.find(p => p.id === plan.stripeId)))
      .map((plan) => new Plan(plan, stripePlans.find((p) => p.id === plan.stripeId)))
    this.plans = plans
  },
  computed: {
    activePlans() {
      return this.plans.filter((p) => p.active)
    },
  },
  methods: {
    async addPlan() {
      if (!this.service.stripeId) {
        const addProductResponse = await createStripeProduct(this.id)
        console.log({ addProductResponse })
      }
      let allowance = null
      if (this.newPlan.allowance && this.newPlan.allowanceType === 'limited') {
        // eslint-disable-next-line
        allowance = this.newPlan.allowance
      }
      await createStripePlan(this.id, {
        name: this.newPlan.name,
        allowance,
        amount: Math.round(this.newPlan.amount * 100),
        description: this.newPlan.description,
      })
      this.newPlan = Object.assign({}, newPlanBase)
    },
    // removePlan(localId) {
    //   this.service.plans = this.service.plans.filter(plan => plan.localId !== +localId)
    //   this.$emit('update:plans', this.service.plans)
    // },
    // addPlan() {
    //   const newPlan = {
    //     localId: this.planId,
    //   }
    //   this.service.previews.push(newPlan)
    //   this.previewId = this.previewId + 1
    //   this.$emit('update:previews', this.service.previews)
    // },
    // async createPlan(id) {

    // },
    // async updatePlan(id) {

    // },
    // savePlans() {
    //   // create plans for all the ones without stripe ids
    //   // update plans for all the ones with stripe ids

    // },
  },
}
</script>
