<template>
<div class="is-clearfix box">
    <el-form label-width="120px">
        <el-form-item label="Input Label">
          <el-input v-model="bound_label"
          v-on:input="$emit('update:label', $event)"
          placeholder="My Input Label"></el-input>
        </el-form-item>
        <el-form-item label="Input Key">
          <el-input v-model="bound_key"
          v-on:input="$emit('update:inputKey', $event)"
          placeholder="my_input"></el-input>
        </el-form-item>
        <el-form-item label="Description">
          <el-input type="textarea" v-model="bound_description"
            v-on:input="$emit('update:description', $event)"
            placeholder="Description of this service input">
          </el-input>
        </el-form-item>
        <el-form-item label="Placeholder">
          <el-input v-model="bound_placeholder"
            v-on:input="$emit('update:placeholder', $event)"
           placeholder="Example input value to make it clear to the customer what you need">
          </el-input>
        </el-form-item>
        <!-- <el-form-item v-if="!required" label="Default Value"> -->
          <!-- <el-input v-model="bound_defaultValue"
          placeholder="Value should we pass to your API if the customer does not enter a value">
         </el-input> -->
        <!-- </el-form-item> -->
        <el-form-item label="Required">
          <el-switch v-model="bound_required"
            v-on:input="$emit('update:required', $event)"
          ></el-switch>
        </el-form-item>
        <slot></slot>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'ServiceInputTemplateText',
  props: {
    label: String,
    inputKey: String,
    description: String,
    placeholder: String,
    defaultValue: String,
    required: Boolean,
  },
  data() {
    return {
      bound_label: this.label,
      bound_key: this.inputKey,
      bound_description: this.description,
      bound_placeholder: this.placeholder,
      bound_defaultValue: this.defaultValue,
      bound_required: this.required,
    }
  },
}
</script>
