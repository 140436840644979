<template>
  <div>
    <section class="section has-small-padding-top">
      <el-tabs tab-position="top" v-model="activeTab">
        <el-tab-pane label="Overview" name="overview">
          <el-form label-width="120px">
            <el-form-item label="Title">
              <el-input
                v-model="service.title"
                v-on:input="$emit('update:title', $event)"
                placeholder="Service Name"
              ></el-input>
            </el-form-item>
            <el-form-item label="Summary">
              <el-input
                v-model="service.summary"
                v-on:input="$emit('update:summary', $event)"
                placeholder="Short Service Description"
              ></el-input>
            </el-form-item>
            <el-form-item label="Description">
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 10 }"
                v-model="service.description"
                v-on:input="$emit('update:description', $event)"
                placeholder="Longer service description about what your service does"
              ></el-input>
            </el-form-item>
            <el-form-item label="Cost ($)">
              <el-input-number
                v-model="service.amount"
                v-on:input="$emit('update:amount', $event)"
                :precision="2"
                :step="1"
                :min="0"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="API Type">
              <el-radio-group v-model="service.apiType" v-on:change="$emit('update:apiType', $event)" size="medium">
                <el-radio-button :label="API_TYPE.RESPONSE">Direct Response</el-radio-button>
                <el-radio-button :label="API_TYPE.POLL">Status Polling</el-radio-button>
                <el-radio-button :label="API_TYPE.PUSH">Webhook</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="Input Endpoint">
              <el-input
                v-model="service.inputEndpoint"
                v-on:input="$emit('update:inputEndpoint', $event)"
                placeholder="https://your-api.com/service"
              ></el-input>
            </el-form-item>
            <el-form-item v-if="service.apiType === 'POLL'" label="Status Endpoint">
              <el-input
                v-model="service.statusEndpoint"
                v-on:input="$emit('update:statusEndpoint', $event)"
                placeholder="https://your-api.com/status"
              ></el-input>
            </el-form-item>
            <el-form-item label="Link" v-if="service.id">
              <router-link :to="`/service/${service.id}`" target="_blank">
                https://market.floom.app/service/{{ service.id }}
                <i class="fas fa-external-link-alt is-primary-color"></i>
              </router-link>
            </el-form-item>
            <el-form-item label="Custom Link" v-if="service.id">
              <div v-if="service.slug && !updatingSlug">
                <router-link :to="`/service/${service.slug}`" target="_blank">
                  https://market.floom.app/service/{{ service.slug }}
                  <i class="fas fa-external-link-alt is-primary-color"></i>
                </router-link>
                <br />
                <el-button class="has-margin-to" type="primary" size="mini" @click="updatingSlug = true"
                  >Change Custom Short Link</el-button
                >
              </div>
              <div v-else>
                <el-input
                  v-model="service.slug"
                  v-on:input="$emit('update:slug', $event)"
                  :placeholder="slugPlaceholder"
                ></el-input>
                https://market.floom.app/service/{{ slugified }}
              </div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="SEO" name="seo">
          <el-form label-width="120px">
            <el-form-item label="SEO Title">
              <el-input
                v-model="service.seoTitle"
                v-on:input="$emit('update:seoTitle', $event)"
                placeholder="The service title will be used by default"
                :maxlength="60"
                :show-word-limit="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="SEO Description">
              <el-input
                v-model="service.seoDescription"
                v-on:input="$emit('update:seoDescription', $event)"
                placeholder="The service description will be used by default"
                :maxlength="160"
                :show-word-limit="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="SEO Image">
              <el-input
                v-model="service.seoImage"
                v-on:input="$emit('update:seoImage', $event)"
                placeholder="Any product images will be used by default"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="Order Form" name="input">
          <el-card
            shadow="hover"
            v-for="input in service.inputs"
            v-bind:key="`input-${input.localId}`"
            v-bind.sync="input"
            class="has-margin-vertical"
          >
            <div slot="header" class="is-clearfix">
              <span class="is-size-4 has-text-weight-bold has-margin-left">{{
                input.label || inputTypeLabel[input.type]
              }}</span>
              <el-button type="text" class="is-pulled-right" @click="removeInputTemplate(input.localId)"
                >Remove Input</el-button
              >
            </div>
            <service-input-template-text
              v-if="input.type === INPUT_TYPE.TEXT"
              v-bind:key="`input-${input.localId}`"
              v-bind.sync="input"
            >
              <br />
            </service-input-template-text>
            <service-input-template-color
              v-if="input.type === INPUT_TYPE.COLOR"
              v-bind:key="`input-${input.localId}`"
              v-bind.sync="input"
            >
              <br />
            </service-input-template-color>
            <service-input-template-file
              v-if="input.type === INPUT_TYPE.FILE"
              v-bind:key="`input-${input.localId}`"
              v-bind.sync="input"
            >
              <br />
            </service-input-template-file>
            <service-input-template-select
              v-if="input.type === INPUT_TYPE.SELECT"
              v-bind:key="`input-${input.localId}`"
              v-bind.sync="input"
            >
              <br />
            </service-input-template-select>
          </el-card>
          <el-button v-on:click="addInputTemplate(INPUT_TYPE.TEXT)">+ Add Text Input</el-button>
          <el-button v-on:click="addInputTemplate(INPUT_TYPE.COLOR)">+ Add Color Input</el-button>
          <el-button v-on:click="addInputTemplate(INPUT_TYPE.FILE)">+ Add File Input</el-button>
          <el-button v-on:click="addInputTemplate(INPUT_TYPE.SELECT)">+ Add Select Input</el-button>
        </el-tab-pane>
        <el-tab-pane label="Preview" name="preview">
          <el-card
            shadow="hover"
            class="has-margin-vertical"
            v-for="preview in service.previews"
            v-bind:key="`preview-${preview.localId}`"
          >
            <div slot="header" class="is-clearfix">
              <el-button type="text" class="is-pulled-right" @click="removePreview(preview.localId)"
                >Remove Preview</el-button
              >
            </div>
            <el-form label-width="120px">
              <el-form-item label="Preview Type">
                <el-radio-group v-model="preview.type" v-on:input="$emit('update:preview', $event)" size="medium">
                  <el-radio-button :label="PREVIEW_TYPE.VIDEO">Video</el-radio-button>
                  <el-radio-button :label="PREVIEW_TYPE.IMAGE">Image</el-radio-button>
                  <el-radio-button :label="PREVIEW_TYPE.LINK">Link</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="Preview URL">
                <el-input
                  type="url"
                  v-model="preview.value"
                  v-on:input="$emit('update:preview', $event)"
                  placeholder="https://your-preview.mp4"
                ></el-input>
              </el-form-item>
              <el-form-item label="Description">
                <el-input
                  type="url"
                  v-model="preview.description"
                  v-on:input="$emit('update:preview', $event)"
                  placeholder="A mockup of my service"
                ></el-input>
              </el-form-item>
            </el-form>
            <br />
          </el-card>
          <el-button v-on:click="addPreview()">+ Add Another Preview</el-button>
        </el-tab-pane>
        <el-tab-pane label="Plans" value="plans" v-if="id">
          <service-plans-edit :id="id"></service-plans-edit>
        </el-tab-pane>
      </el-tabs>
    </section>
  </div>
</template>

<script>
import slugify from 'slugify'
import { API_TYPE, INPUT_TYPE, PREVIEW_TYPE } from '@/graph-constants'
import ServicePlansEdit from '@/components/ServicePlansEdit.vue'
import ServiceInputTemplateText from '@/components/ServiceInputTemplateText.vue'
import ServiceInputTemplateColor from '@/components/ServiceInputTemplateColor.vue'
import ServiceInputTemplateFile from '@/components/ServiceInputTemplateFile.vue'
import ServiceInputTemplateSelect from '@/components/ServiceInputTemplateSelect.vue'

const inputTypeLabel = {
  [INPUT_TYPE.TEXT]: 'Text Input',
  [INPUT_TYPE.FILE]: 'File Input',
  [INPUT_TYPE.SELECT]: 'Select Input',
  [INPUT_TYPE.COLOR]: 'Color Input',
}

export default {
  name: 'ServiceEditor',
  components: {
    ServicePlansEdit,
    ServiceInputTemplateText,
    ServiceInputTemplateColor,
    ServiceInputTemplateFile,
    ServiceInputTemplateSelect,
  },
  props: {
    id: String,
    title: String,
    summary: String,
    description: String,
    slug: String,
    amount: Number,
    apiType: String,
    inputEndpoint: String,
    statusEndpoint: String,
    previews: Array,
    inputs: Array,
    seoTitle: String,
    seoDescription: String,
    seoImage: String,
  },
  data() {
    return {
      API_TYPE,
      INPUT_TYPE,
      PREVIEW_TYPE,
      inputTypeLabel,
      activeTab: 'overview',
      previewTypes: [PREVIEW_TYPE.VIDEO, PREVIEW_TYPE.IMAGE, PREVIEW_TYPE.LINK],
      service: {
        id: this.id,
        title: this.title,
        summary: this.summary,
        description: this.description,
        slug: this.slug,
        previews: this.previews,
        amount: this.amount,
        apiType: this.apiType,
        inputEndpoint: this.inputEndpoint,
        statusEndpoint: this.statusEndpoint,
        inputs: this.inputs,
        seoTitle: this.seoTitle,
        seoDescription: this.seoDescription,
        seoImage: this.seoImage,
      },
      slugInput: '',
      updatingSlug: !this.slug,
      inputId: 0,
      previewId: 0,
    }
  },
  computed: {
    slugified() {
      if (this.service.slug) {
        return slugify(this.service.slug, { lower: true })
      }
      return ''
    },
    slugPlaceholder() {
      return slugify(this.title, { lower: true })
    },
  },
  async created() {
    if (Array.isArray(this.service.inputs)) {
      this.inputId = this.service.inputs.length
    }
    if (Array.isArray(this.service.previews)) {
      this.previewId = this.service.previews.length
    }
  },
  methods: {
    removeInputTemplate(localId) {
      this.service.inputs = this.service.inputs.filter((input) => input.localId !== +localId)
      this.$emit('update:inputs', this.service.inputs)
    },
    addInputTemplate(inputType) {
      const newInput = {
        localId: this.inputId,
        type: inputType,
        inputKey: '',
        label: '',
        accept: '',
        description: '',
        placeholder: '',
        defaultValue: '',
        required: true,
      }
      if (inputType === INPUT_TYPE.SELECT) {
        newInput.options = [{ value: 'option_1', label: 'Option 1', localId: 0 }]
        newInput.selectMultiple = false
        newInput.selectNone = true
        newInput.selectCreate = false
      }
      this.service.inputs.push(newInput)
      this.inputId = this.inputId + 1
      this.$emit('update:inputs', this.service.inputs)
    },
    removePreview(localId) {
      this.service.previews = this.service.previews.filter((preview) => preview.localId !== +localId)
      this.$emit('update:previews', this.service.previews)
    },
    addPreview() {
      const newPreview = {
        localId: this.previewId,
        type: PREVIEW_TYPE.VIDEO,
        value: '',
        description: '',
      }
      this.service.previews.push(newPreview)
      this.previewId = this.previewId + 1
      this.$emit('update:previews', this.service.previews)
    },
  },
}
</script>

<style lang="scss" scoped>
.input__prepend-select {
  width: 110px;
}
.publish {
  margin-top: 16px;
}
</style>
