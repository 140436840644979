<template>
<div class="is-clearfix box">
    <el-form label-width="120px">
        <el-form-item label="Input Label">
          <el-input v-model="bound_label"
          v-on:input="$emit('update:label', $event)"
          placeholder="My Input Label"></el-input>
        </el-form-item>
        <el-form-item label="Input Key">
          <el-input v-model="bound_key"
          v-on:input="$emit('update:inputKey', $event)"
          placeholder="my_input"></el-input>
        </el-form-item>
        <el-form-item label="Description">
          <el-input type="textarea" v-model="bound_description"
            v-on:input="$emit('update:description', $event)"
            placeholder="Description of this service input">
          </el-input>
        </el-form-item>
        <el-form-item label="Options">
          <div v-for="option in bound_options" :key="option.localId">
            <div class="columns">
              <div class="column">
                <p class="has-text-grey">Value</p>
                <el-input v-model="option.value"
                  v-on:input="$emit('update:option', $event)"
                  placeholder="Option Value e.g. gala_apple"
                ></el-input>
              </div>
              <div class="column">
                <p class="has-text-grey">Label</p>
                <el-input v-model="option.label"
                  v-on:input="$emit('update:option', $event)"
                  placeholder="Option Value e.g. Gala Apple"
                ></el-input>
              </div>
            </div>
          </div>
          <el-button class="has-margin-top" v-on:click="addOption()">+ Add Option</el-button>
        </el-form-item>
        <el-form-item label="Required">
          <el-switch v-model="bound_required"
            v-on:input="$emit('update:required', $event)"
          ></el-switch>
        </el-form-item>
        <el-form-item label="Allow None">
          <el-switch v-model="bound_selectNone"
            v-on:input="$emit('update:selectNone', $event)"
          ></el-switch>
        </el-form-item>
        <el-form-item label="Allow Multiple">
          <el-switch v-model="bound_selectMultiple"
            v-on:input="$emit('update:selectMultiple', $event)"
          ></el-switch>
        </el-form-item>
        <el-form-item label="Allow Create">
          <el-switch v-model="bound_selectCreate"
            v-on:input="$emit('update:selectCreate', $event)"
          ></el-switch>
        </el-form-item>
      <slot></slot>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'ServiceInputTemplateSelect',
  props: {
    label: String,
    inputKey: String,
    description: String,
    defaultValue: String,
    options: Array,
    selectMultiple: Boolean,
    selectNone: Boolean,
    selectCreate: Boolean,
    required: Boolean,
  },
  data() {
    return {
      bound_label: this.label,
      bound_key: this.inputKey,
      bound_description: this.description,
      bound_defaultValue: this.defaultValue,
      bound_options: this.options,
      bound_selectMultiple: this.selectMultiple,
      bound_selectNone: this.selectNone,
      bound_selectCreate: this.selectCreate,
      bound_required: this.required,
      optionId: this.options ? this.options.length : 0,
    }
  },
  methods: {
    addOption() {
      const newOption = {
        localId: this.optionId,
        value: `option_${this.optionId + 1}`,
        label: `Option ${this.optionId + 1}`,
      }
      this.bound_options.push(newOption)
      this.optionId = this.optionId + 1
      this.$emit('update:options', this.bound_options)
    }
  }
}
</script>
